import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { CelulaService } from '../service/celula.service';
import { CelulaBuilder } from 'src/app/builder/celula.builder';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { MembroComunidadeService } from '../service/membro.comunidade.service';
import { ImagemService } from '../../imagem/service/imagem.service';
import { Pessoa } from 'src/app/model/pessoa.model';
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { FormadorComunitarioService } from '../service/formador.comunitario.service';
import { FormadorAssistente } from 'src/app/model/formadorassistente.model';

@Component({
    selector: 'celula-manage',
    templateUrl: './celula.manage.component.html'
})
export class CelulaManageComponent implements OnInit {

    public celulaId: number;
    public celula: Celula;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public fases : Array<ElementoGrupoDominio>;

    public formadorComunitario: FormadorComunitario;
    public formadoresComunitarios: Array<FormadorComunitario>;
    public pessoaFormadorComunitarioSelecionada: Pessoa;
    public buscaFormadoresComunitarios: any;
    public totalDePaginasFormadoresComunitarios: number = 0;

    public formadorAssistente: FormadorAssistente;
    public formadoresAssistentes: Array<FormadorAssistente>;
    public pessoaFormadorAssistenteSelecionada: Pessoa;
    public buscaFormadoresAssistentes: any;
    public totalDePaginasFormadoresAssistentes: number = 0;

    public membrosCelula: Array<MembroComunidade>;
    public buscaMembros: any;
    public totalDePaginasMembros: number = 0;
    
    

    constructor(
        public celulaService: CelulaService,
        public celulaBuilder: CelulaBuilder,
        public membroComunidadeBuilder: MembroComunidadeBuilder,
        public membroService: MembroComunidadeService,
        public formadorComunitarioService: FormadorComunitarioService,
        public imagemService: ImagemService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) {
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
        this.fases = new Array<ElementoGrupoDominio>();
        this.celula = this.celulaBuilder.getInstance();
        this.membrosCelula = new Array<MembroComunidade>();
        this.formadoresComunitarios = new Array<FormadorComunitario>();
    }

    ngOnInit() {
        this.celulaId = Number(this.route.snapshot.paramMap.get('id'));
        this.formadorComunitario = this.novoFormadorComunitarioCelula();
        this.formadorAssistente = this.novoFormadorAssistenteCelula();
        this.carregarCelula();

        this.buscaMembros = {
            celulaId : this.celulaId,
            pageNumber : 0, 
            quantityOfElements : 100
        };
        this.carregarMembrosCelula();

        this.buscaFormadoresComunitarios = {
            celulaId : this.celulaId,
            pageNumber : 0, 
            quantityOfElements : 100
        };
        this.carregarFormadoresComunitarios();

        this.buscaFormadoresAssistentes = {
            celulaId : this.celulaId,
            pageNumber : 0, 
            quantityOfElements : 100
        };
        this.carregarFormadoresAssistentes();
    }    

    /**
     * Formador Comunitário
     */

    cliqueNoBotaoFormadoresComunitariosListener(botao) {
        this.buscaFormadoresComunitarios.pageNumber = botao.numero-1;
        this.buscaFormadoresComunitarios();
    } 

    novoFormadorComunitarioCelula() : FormadorComunitario {
        return new FormadorComunitario(null,2,this.celulaId,null,null,null,null,null,null,null,null,false);
    }

    async salvarFormadorComunitario() : Promise<any> {
        try 
        {
            if(this.formadorComunitario.pessoaId != null)
            {
                let response = await this.celulaService.cadastrarFormadorComunitario(this.formadorComunitario);
                this.formadorComunitario = this.novoFormadorComunitarioCelula();
                this.pessoaFormadorComunitarioSelecionada = undefined;
                this.carregarFormadoresComunitarios();
                this.swtAlert2Service.successAlert("Formador comunitário cadastrado com sucesso!");
            }
            else
            {
                this.swtAlert2Service.warningAlert("Selecione a pessoa para vincular como formador comunitário.");
            }            
        } 
        catch (e) 
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }

    async carregarFormadoresComunitarios() : Promise<any> {

        try
        {

            let response = await this.celulaService.listarFormadorComunitarioPorCelulaId(this.celulaId);
            this.formadoresComunitarios = response;
            this.totalDePaginasFormadoresComunitarios = 1;
            //this.formadoresComunitarios = response.content;
            //this.totalDePaginasFormadoresComunitarios = response.totalPages;

            if(this.formadoresComunitarios.length > 0)
            {
                this.formadoresComunitarios = this.formadoresComunitarios.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
            }

            for(let i = 0; i < this.formadoresComunitarios.length; i++)
            {
                if(this.formadoresComunitarios[i].pessoaImagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.formadoresComunitarios[i].pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadoresComunitarios[i]);
                }
            }

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async atualizarFormador(formador: FormadorComunitario) { 

        try 
        {
            let response = await this.celulaService.atualizarFormadorComunitario(formador);
            this.carregarFormadoresComunitarios();
            this.swtAlert2Service.successAlert("Formador Comunitário atualizado com sucesso!")
        } 
        catch (e) 
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async removerFormador(id: number) { 

        try 
        {
            if(confirm("Deseja deletar esse formador?"))
            {
                let response = await this.celulaService.deletarFormadorComunitario(id);
                this.carregarFormadoresComunitarios();
                this.swtAlert2Service.successAlert("Formador Comunitário removido com sucesso!")
            }
            
        } 
        catch (e) 
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async selecionarFormadorComunitario(pessoa): Promise<any> {
        this.formadorComunitario.pessoaId = pessoa.id;
        this.pessoaFormadorComunitarioSelecionada = pessoa;
        $('#listagem-pessoa-formador-comunitario').modal('hide');
    }

    /**
     * Formador Assistente
     */

    cliqueNoBotaoFormadoresAssistentesListener(botao) {
        this.buscaFormadoresAssistentes.pageNumber = botao.numero-1;
        this.buscaFormadoresAssistentes();
    }

    novoFormadorAssistenteCelula() : FormadorAssistente {
        return new FormadorAssistente(null,2,this.celulaId,null,null,null,null,null,null,null,null,false);
    }

    async salvarFormadorAssistente() : Promise<any> {
        try 
        {
            if(this.formadorAssistente.pessoaId != null)
            {
                let response = await this.celulaService.cadastrarFormadorAssistente(this.formadorAssistente);
                this.formadorAssistente = this.novoFormadorAssistenteCelula();
                this.pessoaFormadorAssistenteSelecionada = undefined;
                this.carregarFormadoresAssistentes();
                this.swtAlert2Service.successAlert("Formador comunitário cadastrado com sucesso!");
            }
            else
            {
                this.swtAlert2Service.warningAlert("Selecione a pessoa para vincular como formador comunitário.");
            }            
        } 
        catch (e) 
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }

    async carregarFormadoresAssistentes() : Promise<any> {

        try
        {

            let response = await this.celulaService.listarFormadorAssistentePorCelulaId(this.celulaId);
            this.formadoresAssistentes = response;
            this.totalDePaginasMembros = 1;
            //this.formadoresAssistentes = response.content;
            //this.totalDePaginasFormadoresAssistentes = response.totalPages;

            if(this.formadoresAssistentes.length > 0)
            {
                this.formadoresAssistentes = this.formadoresAssistentes.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
            }

            for(let i = 0; i < this.formadoresAssistentes.length; i++)
            {
                if(this.formadoresAssistentes[i].pessoaImagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.formadoresAssistentes[i].pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadoresAssistentes[i]);
                }
            }

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async atualizarFormadorAssistente(formador: FormadorAssistente) { 

        try 
        {
            let response = await this.celulaService.atualizarFormadorAssistente(formador);
            this.carregarFormadoresAssistentes();
            this.swtAlert2Service.successAlert("Formador Comunitário atualizado com sucesso!")
        } 
        catch (e) 
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async removerFormadorAssistente(id: number) { 

        try 
        {
            if(confirm("Deseja deletar esse formador?"))
            {
                let response = await this.celulaService.deletarFormadorAssistente(id);
                this.carregarFormadoresAssistentes();
                this.swtAlert2Service.successAlert("Formador Comunitário removido com sucesso!")
            }
            
        } 
        catch (e) 
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async selecionarFormadorAssistente(pessoa): Promise<any> {
        this.formadorAssistente.pessoaId = pessoa.id;
        this.pessoaFormadorAssistenteSelecionada = pessoa;
        $('#listagem-pessoa-formador-assistente').modal('hide');
    }


    /**
     * Formador Célula
     */

    async carregarCelula() : Promise<any> {

        try 
        {
            let response = await this.celulaService.find(this.celulaId);
            this.celula = response.entity;
        } 
        catch (e) 
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    cliqueNoBotaoMembrosListener(botao) {
        this.buscaMembros.pageNumber = botao.numero-1;
        this.buscaMembros();
    }

    async carregarMembrosCelula() : Promise<any> {

        try
        {

            let response = await this.membroService.listarMembrosCelula(this.celulaId);
            this.membrosCelula = response;
            this.totalDePaginasMembros = 1;
            //this.membrosCelula = response.content;
            //this.totalDePaginasMembros = response.totalPages;

            if(this.membrosCelula.length > 0)
            {
                this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
            }

            for(let i = 0; i < this.membrosCelula.length; i++)
            {
                if(this.membrosCelula[i].pessoaImagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                }
            }

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

}