import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
export class ParecerCelulaAssistenteFormacaoComponent {
    constructor(imageHandlerService, celulaService, membroService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, route, router, swtAlert2Service) {
        this.imageHandlerService = imageHandlerService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCelulaAssociada = false;
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.celulaId = Number(this.route.snapshot.paramMap.get('id'));
            yield this.carregarCorStatusVocacional();
            yield this.carregarMembrosCelula();
        });
    }
    carregarCorStatusVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.listaCorStatusVocacional =
                    yield this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao('COR_STATUS_VOCACIONAL');
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMembrosCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.membrosCelula = yield this.membroService.listarMembrosCelula(this.celulaId);
                this.totalDePaginasMembros = 1;
                if (this.membrosCelula.length > 0) {
                    this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                this.membrosCelula.forEach((m) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (m.pessoaImagemId != null) {
                        m.thumbnail = yield this.imageHandlerService.getPessoaImagem(m.pessoaImagemId);
                    }
                }));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(JSON.stringify(err.error));
            }
        });
    }
    cadastrarConsideracaoConselhoLocal(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(membro);
                yield this.carregarMembrosCelula();
                this.swtAlert2Service.successAlert('Parecer de consideração do Conselho Local atualizado com sucesso!');
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.carregarMembrosCelula();
    }
    concordanciaConselhoLocal(membro) {
        if (membro.consideracaoCondorda === true) {
            membro.caminhoFormativoFeedback =
                membro.caminhoFormativoSugeridoFormadorComunitario;
            membro.consideracaoConselhoLocal = '';
        }
        else {
            membro.caminhoFormativoFeedback = '';
        }
    }
    copiarTextoConsideracaoConselhoLocalParaFeedback(membro) {
        membro.caminhoFormativoFeedback = membro.consideracaoConselhoLocal;
    }
    obterCorDaLinha(membro) {
        let cssObject = {};
        if (membro.corStatusVocacionalValor != null) {
            cssObject['background-color'] = membro.corStatusVocacionalValor;
        }
        if (membro.corStatusVocacionalNome != null &&
            membro.corStatusVocacionalNome.localeCompare('Desligamento') == 0) {
            cssObject['color'] = '#fff';
        }
        return cssObject;
    }
}
