import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Page } from 'src/app/model/page.model';
import {
  MissaoConfigService,
  QuestionarioConfiguracao,
} from '../../service/missao-config.service';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';

@Component({
  selector: 'app-listagem-periodo-liberacao-questionario-parecer',
  templateUrl:
    './listagem-periodo-liberacao-questionario-parecer.component.html',
  styleUrls: [
    './listagem-periodo-liberacao-questionario-parecer.component.css',
  ],
})
export class ListagemPeriodoLiberacaoQuestionarioParecerComponent
  implements OnInit
{
  missaoConfiguracaoId?: number = null;
  paginaPeriodoLiberacao?: Page<QuestionarioConfiguracao>;

  constructor(
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private missaoConfigService: MissaoConfigService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.missaoConfiguracaoSharedDataService.missaoConfiguracaoId$.subscribe(
      (id: number) => {
        this.missaoConfiguracaoId = id;
        this.carregarConfiguracaoQuestionario();
      }
    );
  }

  carregarConfiguracaoQuestionario() {
    this.missaoConfigService
      .obterPaginaPorMissaoConfiguracaoId(this.missaoConfiguracaoId)
      .subscribe((page) => {
        this.paginaPeriodoLiberacao = page;
      });
  }

  atualizarPeriodo(periodo: QuestionarioConfiguracao) {
    this.missaoConfigService
      .atualizarQuestionarioConfiguracao(periodo)
      .subscribe(
        () => {
          this.swtAlert.successAlert(
            'Configuração do questionário atualizada com sucesso!'
          );
          this.carregarConfiguracaoQuestionario();
        },
        (error) => {
          this.swtAlert.errorAlert(error.error.errors);
        }
      );
  }
}
