import { Component, OnInit } from '@angular/core';
import { Pessoa } from 'src/app/model/pessoa.model';
import { ImageHandlerService } from 'src/app/secured/local/mensagem-rl/service/image.haddler.service';
import { LoggedInUserSharedDataService } from '../../../header/service/logged-in-user-shared-data.service';

@Component({
  selector: 'left-sidebar-aside-container-component',
  templateUrl: './left-sidebar-aside-container.component.html',
  styleUrls: ['./style/styles.scss'],
})
export class LeftSidebarAsideContainerComponent implements OnInit {
  person?: Pessoa;
  personThumbnail?: string = null;

  constructor(
    private loggedInUserSharedDataService: LoggedInUserSharedDataService,
    private imagemHandlerService: ImageHandlerService
  ) {}

  ngOnInit(): void {
    this.loggedInUserSharedDataService.loggedInPerson$.subscribe(
      async (person: Pessoa) => {
        this.person = person;
        if (person.imagemId != null)
          this.personThumbnail =
            await this.imagemHandlerService.getPessoaImagem(person.imagemId);
      }
    );
  }
}
