<secured>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 i18n>Configuração da Missão</h5>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <app-missao-display-data *ngIf="responsavelLocalMissaoId"
        [missaoId]="responsavelLocalMissaoId"></app-missao-display-data>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4">
          <app-missao-config-form-component></app-missao-config-form-component>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
          <app-listagem-periodo-liberacao-questionario-parecer></app-listagem-periodo-liberacao-questionario-parecer>
        </div>
      </div>

    </section>
  </div>
</secured>