<div class="row">
  <div class="col-12">
    <form (ngSubmit)="salvarConfiguracao($event)" #form="ngForm">
      <div class="card card-gray-dark">
        <div class="card-header">
          <h3 class="card-title">Informações da Missão</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" name="has_colegiado" id="has_colegiado"
                        [(ngModel)]="missaoConfiguracao.hasColegiado" class="form-check-input">
                      <label for="has_colegiado">Possui colegiado?</label>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" name="has_comissao_discernimento" id="has_comissao_discernimento"
                        [(ngModel)]="missaoConfiguracao.hasComissaoDiscernimento" class="form-check-input">
                      <label for="has_comissao_discernimento">Possui Comissão de Discernimento?</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row justify-content-end">
            <button type="submit" class="btn btn-primary">{{missaoConfiguracao.id ? 'Atualizar' : 'Salvar'}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>