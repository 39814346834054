import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { environment } from 'src/environments/environment';


@Injectable()
export class MembroComunidadeService extends GenericService<MembroComunidade> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('membrocomunidade');
    }

    buscarPorCelula(busca: any) : Promise<any> {
        return this.http.post<any>(environment.moduloFormacao.urlModuloFormacao+'membrocomunidade/porcelula', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarPorCasaComunitaria(busca: any) : Promise<any> {
        return this.http.post<any>(environment.moduloFormacao.urlModuloFormacao+'membrocomunidade/porcasacomunitaria', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }    

    listarMembrosCelula(id: number) : Promise<any> {
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}membrocomunidade/celula/porid/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    listarMembrosCasaComunitaria(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}membrocomunidade/porcasacomunitaria/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }    

    vincularMembroCelula(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincularmembrocelula', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    vincularMembroCasaComunitaria(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincularmembrocasacomunitaria', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }    

    desvincularMembroCelula(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincularmembrocelula', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    desvincularMembroCasaComunitaria(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincularmembrocasacomunitaria', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }  
    
    detalhesMembroCelula(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/detalhesmembro', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }  
    
    detalhesMembroCasaComunitaria(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/detalhesmembro', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }    

    buscarPorPessoaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    aniversariantesCelula(celulaId: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/celula/aniversariantes/${celulaId}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    aniversariantesCasaComunitaria(casaComunitariaId: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/casacomunitaria/aniversariantes/${casaComunitariaId}`, { 'headers' : this.getHeaders() }).toPromise();
    }

}