import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Pessoa } from 'src/app/model/pessoa.model';
import { LoggedInUserSharedDataService } from 'src/app/secured/layout/header/service/logged-in-user-shared-data.service';
import {
  MissaoConfigService,
  MissaoConfiguracao,
} from '../../service/missao-config.service';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';

@Component({
  selector: 'app-missao-config-form-component',
  templateUrl: './missao-config-form-component.component.html',
  styleUrls: ['./missao-config-form-component.component.css'],
})
export class MissaoConfigFormComponentComponent implements OnInit {
  missaoId?: number;
  missaoConfiguracao: MissaoConfiguracao = {
    id: null,
    missaoId: null,
    hasColegiado: false,
    hasComissaoDiscernimento: false,
  };

  constructor(
    private loggedInUserSharedDataService: LoggedInUserSharedDataService,
    private missaoConfiguracaoService: MissaoConfigService,
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.loggedInUserSharedDataService.loggedInPerson$.subscribe(
      (person: Pessoa) => {
        if (person) {
          this.missaoId = person.vinculoMissao.missaoAtualId;
          if (this.missaoId) {
            this.missaoConfiguracao.missaoId = this.missaoId;
            this.carregarMissaoConfiguracao();
          }
        }
      }
    );
  }

  carregarMissaoConfiguracao() {
    this.missaoConfiguracaoService.obterPorResponsavelLocal().subscribe(
      (missaoConfiguracao) => {
        this.missaoConfiguracao = missaoConfiguracao;
        this.missaoConfiguracaoSharedDataService.updateMissaoConfiguracaoId(
          this.missaoConfiguracao.id
        );
      },
      (error) => {
        this.salvarConfiguracao();
      }
    );
  }

  salvarConfiguracao($event?: Event) {
    if ($event) $event.preventDefault();
    if (!this.missaoConfiguracao.id) {
      this.missaoConfiguracaoService
        .cadastrar(this.missaoConfiguracao)
        .subscribe((dadosRetorno) => {
          this.missaoConfiguracao = dadosRetorno;
          this.missaoConfiguracaoSharedDataService.updateMissaoConfiguracaoId(
            this.missaoConfiguracao.id
          );
        });
    } else {
      this.missaoConfiguracaoService
        .atualizar(this.missaoConfiguracao)
        .subscribe((dadosRetorno) => {
          this.missaoConfiguracao = dadosRetorno;
          this.swtAlert.successAlert(
            'Configuração da missão atualizada com sucesso!'
          );
        });
    }
  }
}
