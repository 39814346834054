export class QuestionarioFormando {

    constructor(
        public id: number,
        public pessoaId: number,
        public questionarioId: number,
        public anoFormativoId: number,
        public status: string,
        public data_envio: Date     
    ){}

}