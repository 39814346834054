import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { MissaoConfigFormComponentComponent } from './components/missao-config-form-component/missao-config-form-component.component';
import { MissaoConfigComponent } from './missao-config.component';
import { MissaoConfigRoutes } from './missao-config.routing';
import { MissaoConfigService } from './service/missao-config.service';
import { MissaoDisplayDataComponent } from './components/missao-display-data-component/missao-display-data.component';
import { ListagemPeriodoLiberacaoQuestionarioParecerComponent } from './components/listagem-periodo-liberacao-questionario-parecer-component/listagem-periodo-liberacao-questionario-parecer.component';
import { MissaoConfiguracaoSharedDataService } from './service/missao-configuracao-shared-data.service';

@NgModule({
  imports: [CommonModule, MissaoConfigRoutes, SecuredModule, FormsModule],
  declarations: [
    MissaoConfigComponent,
    MissaoConfigFormComponentComponent,
    MissaoDisplayDataComponent,
    ListagemPeriodoLiberacaoQuestionarioParecerComponent,
  ],
  providers: [MissaoConfigService, MissaoConfiguracaoSharedDataService],
})
export class MissaoConfigModule {}
