import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { ParecerResponsavel } from '../model/parecerresponsavel.model';

@Injectable()
export class ParecerResponsavelBuilder extends AbstractBuilder<ParecerResponsavel> {

    reset() : void {
        this.entity = new ParecerResponsavel(null,null,null,null,null,null);
    }

}