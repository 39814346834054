import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';

@Injectable()
export class QuestionarioFormandoService extends GenericService<QuestionarioFormando> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType('questionarioformando');
  }

  buscarPorPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/porpessoaid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  cadastrar(request: any) {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizar(id: number, request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/${id}`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }
}
