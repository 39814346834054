import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { CelulaService } from 'src/app/secured/wop/celula/service/celula.service';
import { FormadorComunitarioService } from 'src/app/secured/wop/celula/service/formador.comunitario.service';
import { MembroComunidadeService } from 'src/app/secured/wop/celula/service/membro.comunidade.service';
import { ElementoGrupoDominioLocalService } from '../../../grupodominio/service/elementogrupodominio.local.service';
import { ImageHandlerService } from '../../../mensagem-rl/service/image.haddler.service';
import { ParecerResponsavelService } from '../../../parecer/service/parecerresponsavel.service';
import { CaminhoFormativoService } from '../../../questionario/service/caminhoformativo.service';
import { QuestionarioFormandoService } from '../../../questionario/service/questionarioformando.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';

@Component({
  selector: 'discernimentos-assistente-formacao',
  templateUrl: './parecer.celula.assistenteformacao.component.html',
  styleUrls: [],
})
export class ParecerCelulaAssistenteFormacaoComponent implements OnInit {
  public celula: Celula;
  public formadorComunitario: FormadorComunitario;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;

  public totalDePaginas: number = 1;

  public totalDePaginasMembros: number;
  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public celulaId: number;

  public listaCorStatusVocacional!: Array<any>;

  constructor(
    private imageHandlerService: ImageHandlerService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public formadorComunitarioService: FormadorComunitarioService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public caminhoFormativoService: CaminhoFormativoService,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
  }

  async initializer(): Promise<any> {
    this.celulaId = Number(this.route.snapshot.paramMap.get('id'));
    await this.carregarCorStatusVocacional();
    await this.carregarMembrosCelula();
  }

  async carregarCorStatusVocacional(): Promise<any> {
    try {
      this.listaCorStatusVocacional =
        await this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao(
          'COR_STATUS_VOCACIONAL'
        );
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMembrosCelula(): Promise<any> {
    try {
      this.membrosCelula = await this.membroService.listarMembrosCelula(
        this.celulaId
      );
      this.totalDePaginasMembros = 1;

      if (this.membrosCelula.length > 0) {
        this.membrosCelula = this.membrosCelula.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      this.membrosCelula.forEach(async (m) => {
        if (m.pessoaImagemId != null) {
          m.thumbnail = await this.imageHandlerService.getPessoaImagem(
            m.pessoaImagemId
          );
        }
      });
    } catch (err) {
      this.swtAlert2Service.errorAlert(JSON.stringify(err.error));
    }
  }

  async cadastrarConsideracaoConselhoLocal(membro): Promise<any> {
    try {
      await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
        membro
      );
      await this.carregarMembrosCelula();
      this.swtAlert2Service.successAlert(
        'Parecer de consideração do Conselho Local atualizado com sucesso!'
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.carregarMembrosCelula();
  }

  concordanciaConselhoLocal(membro: any) {
    if (membro.consideracaoCondorda === true) {
      membro.caminhoFormativoFeedback =
        membro.caminhoFormativoSugeridoFormadorComunitario;
      membro.consideracaoConselhoLocal = '';
    } else {
      membro.caminhoFormativoFeedback = '';
    }
  }

  copiarTextoConsideracaoConselhoLocalParaFeedback(membro: any) {
    membro.caminhoFormativoFeedback = membro.consideracaoConselhoLocal;
  }

  obterCorDaLinha(membro: any) {
    let cssObject = {};

    if (membro.corStatusVocacionalValor != null) {
      cssObject['background-color'] = membro.corStatusVocacionalValor;
    }

    if (
      membro.corStatusVocacionalNome != null &&
      membro.corStatusVocacionalNome.localeCompare('Desligamento') == 0
    ) {
      cssObject['color'] = '#fff';
    }

    return cssObject;
  }
}
