import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
export class ParecerCoordenadorColegiadoCelulaComponent {
    constructor(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, coordenadorColegiadoService, formadorComunitarioService, formadorAssistenteService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, discernimentoService, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.coordenadorColegiadoService = coordenadorColegiadoService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.discernimentoService = discernimentoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCelulaAssociada = false;
        this.paginacaoConfig = {
            page: 0,
            size: 10,
            totalPages: 0,
            totalElements: 0,
        };
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.carregarFormadoresCelula();
        });
    }
    carregarQuestionarioConfiguracao(questionarioId) {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe((questionarioConfiguracao) => (this.questionarioConfiguracao = questionarioConfiguracao));
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarQuestionario(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorPessoaId(pessoaId);
                if (response != undefined && response != null) {
                    return response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFormadoresCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.discernimentoService.obterMembrosParaDiscernimentoDoResponsavelLocal(this.paginacaoConfig.page, this.paginacaoConfig.size);
                this.formadoresDaMissao = response.content;
                this.paginacaoConfig.totalPages = response.totalPages;
                this.paginacaoConfig.totalElements = response.totalElements;
                if (this.formadoresDaMissao.length > 0) {
                    this.formadoresDaMissao = this.formadoresDaMissao.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.formadoresDaMissao.length; i++) {
                    if (this.formadoresDaMissao[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.formadoresDaMissao[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
                    }
                    if (this.formadoresDaMissao[i].questionarioFormandoStatus == 'NAOINICIADO') {
                        this.formadoresDaMissao[i].questionarioFormandoStatus =
                            'Não Iniciado';
                    }
                    if (this.formadoresDaMissao[i].parecerStatus == 'NAOINICIADO') {
                        this.formadoresDaMissao[i].parecerStatus = 'Não Iniciado';
                    }
                    if (!this.questionarioFormando || !this.questionarioFormando.id) {
                        this.questionarioFormando = yield this.carregarQuestionario(this.formadoresDaMissao[i].pessoaId);
                        if (this.questionarioFormando) {
                            if (!this.questionarioConfiguracao) {
                                this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                            }
                        }
                    }
                    else if (!this.questionarioConfiguracao) {
                        this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoParecerCAL(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                this.parecerResponsavel.parecer_id = 2;
                this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                let response = yield this.parecerResponsavelService.cadastrar(this.parecerResponsavel);
                if (response != undefined && response != null) {
                    this.parecerResponsavel = response;
                    this.router.navigate([
                        '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
                    ]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    consultarVinculoParecerCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (formando.questionarioFormandoId != null) {
                    let response = yield this.parecerResponsavelService.buscarPorQuestionarioFormandoId(formando.questionarioFormandoId);
                    if (response != undefined && response != null) {
                        this.parecerResponsavel = response;
                        this.router.navigate([
                            '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
                        ]);
                    }
                    else {
                        this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
                    }
                }
                if (formando.questionarioFormandoId == null) {
                    this.questionarioFormando = yield this.cadastrarVinculoQuestionarioCAL(formando.pessoaId);
                    this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioCAL(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.questionarioFormando.anoFormativoId = 2;
                this.questionarioFormando.questionarioId = 3;
                this.questionarioFormando.pessoaId = pessoaId;
                let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                if (response != undefined && response != null) {
                    return response;
                }
                else {
                    this.swtAlert2Service.errorAlert('Não foi possível cadastrar um vinculo de questionário para o formando');
                    throw new Error('Não foi possível cadastrar um vinculo de questionário para o formando');
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.paginacaoConfig.page = botao.numero - 1;
        this.carregarFormadoresCelula();
    }
}
