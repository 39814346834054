import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Usuario } from 'src/app/model/usuario.model';
import { CentroEvangelizacaoService } from 'src/app/secured/wop/centroevangelizacao/service/centroevangelizacao.service';
import { EstadoService } from 'src/app/secured/wop/estado/service/estado.service';
import { ElementoGrupoDominioService } from 'src/app/secured/wop/grupodominio/service/elementogrupodominio.service';
import { MissaoService } from 'src/app/secured/wop/missao/service/missao.service';
import { PaisService } from 'src/app/secured/wop/pais/service/pais.service';
import { PessoaService } from 'src/app/secured/wop/pessoa/service/pessoa.service';
import { RegionalService } from 'src/app/secured/wop/regional/service/regional.service';
import { UsuarioService } from 'src/app/secured/wop/usuario/service/usuario.service';
import { CelulaService } from '../service/celula.service';

@Component({
  selector: 'celulas-rl',
  templateUrl: './celulas.rl.component.html',
})
export class CelulasRLComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public fases: Array<ElementoGrupoDominio>;
  public centrosEvangelizacao: Array<CentroEvangelizacao>;
  public celulas: Array<Celula>;
  public regionalSelecionadoId: number;
  public missaoSelecionadoId: number;
  public busca: any;
  public totalDePaginas: number;

  constructor(
    public usuarioService: UsuarioService,
    public pessoaService: PessoaService,
    public regionalService: RegionalService,
    public missaoService: MissaoService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public centroEvangelizacaoService: CentroEvangelizacaoService,
    public celulaService: CelulaService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.fases = new Array<ElementoGrupoDominio>();
    this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
    this.busca = {
      nome: null,
      ano: null,
      faseId: null,
      centroEvangelizacaoId: null,
      pageNumber: 0,
      quantityOfElements: 10,
    };
  }

  ngOnInit() {
    this.initializer();
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    this.carregarNiveisDaCelula();
    this.buscarCelulas();
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
      if (this.pessoa.id) {
        if (this.pessoa.vinculoMissao.missaoAtualId) {
          this.carregarCevPorMissao(this.pessoa.vinculoMissao.missaoAtualId);
        } else if (this.pessoa.vinculoDifusao.difusaoAtualId) {
          this.carregarCevPorDifusao(this.pessoa.vinculoDifusao.difusaoAtualId);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.busca.pageNumber = botao.numero - 1;
    this.buscarCelulas();
  }

  carregarCevPorMissao(missaoId: number): void {
    this.centroEvangelizacaoService
      .porMissao(missaoId)
      .then((response: any) => {
        this.centrosEvangelizacao = response.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  carregarCevPorDifusao(difusaoId: number): void {
    this.centroEvangelizacaoService
      .porDifusao(difusaoId)
      .then((response: any) => {
        this.centrosEvangelizacao = response.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  carregarNiveisDaCelula(): void {
    let buscaFase = {
      grupoNome: 'FASE_CELULA',
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFase)
      .then((lista: any) => {
        this.fases = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  buscarCelulas(): void {
    this.celulaService
      .buscarCelulas(this.busca)
      .then((response: any) => {
        this.celulas = response.entity;
        this.totalDePaginas = response.quantity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }
}
