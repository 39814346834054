import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MissaoConfiguracaoSharedDataService {
  missaoConfiguracaoId$ = new BehaviorSubject<number>(null);

  constructor() {}

  updateMissaoConfiguracaoId = (id: number) => {
    this.missaoConfiguracaoId$.next(id);
  };
}
