<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Discernimentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'"
                                        [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="11"
                                        [rows]="100" [totalDePaginas]="1" (eventoBotao)="cliqueNoBotaoListener($event)">
                                        <tr tableheader>
                                            <th>Foto de perfil</th>
                                            <th>Nome</th>
                                            <th>Nível Formativo</th>
                                            <th>Questionario</th>
                                            <th>Parecer</th>
                                            <th>Caminho Formativo (FC)</th>
                                            <th>Concorda?</th>
                                            <th>Considerações CL</th>
                                            <th>Caminho Formativo (Feedback)</th>
                                            <th>Status Vocacional</th>
                                        </tr>
                                        <tr tablebody *ngFor="let membro of membrosCelula"
                                            [ngStyle]="obterCorDaLinha(membro)">
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                                                        [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail"
                                                        alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{membro.pessoaNome}}</td>
                                            <td>{{membro.nivelFormativoNome}}</td>
                                            <td>
                                                <button *ngIf="membro.questionarioFormandoStatus == 'ENVIADO'"
                                                    [routerLink]="[ '/secured/questionariocal-view-answer', membro.questionarioFormandoId ]"
                                                    class="btn btn-primary" title="Questionario CAL"
                                                    alt="Questionario CAL">Visualizar</button>
                                                <button *ngIf="membro.questionarioFormandoStatus != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Questionario CAL"
                                                    alt="Questionario CAL">{{membro.questionarioFormandoStatus}}</button>
                                            <td>
                                                <button *ngIf="membro.parecerStatus == 'ENVIADO'"
                                                    [routerLink]="[ '/secured/parecer-cc-view', membro.parecerId ]"
                                                    class="btn btn-primary" title="Parecer CAL"
                                                    alt="Parecer CAL">Visualizar</button>
                                                <button *ngIf="membro.parecerStatus != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Parecer CAL"
                                                    alt="Parecer CAL">{{membro.parecerStatus}}</button>
                                            </td>
                                            <td>
                                                {{membro.caminhoFormativoSugeridoFormadorComunitario}}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando">
                                                    <select class="form-control" id="nparecer_concorda"
                                                        [(ngModel)]="membro.consideracaoCondorda"
                                                        name="nparecer_concorda"
                                                        (change)="concordanciaConselhoLocal(membro)">
                                                        <option [ngValue]="null" disabled>Selecione uma opção</option>
                                                        <option [ngValue]="true" i18n>Sim</option>
                                                        <option [ngValue]="false" i18n>Não</option>
                                                    </select>
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{ membro.consideracaoCondorda ? 'Sim' : membro.consideracaoCondorda
                                                    == false ? 'Não' : ''}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando && !membro.consideracaoCondorda">
                                                    <input [(ngModel)]="membro.consideracaoConselhoLocal"
                                                        class="form-control" id="nparecer_consideracoescl"
                                                        (change)="copiarTextoConsideracaoConselhoLocalParaFeedback(membro)">
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{membro.consideracaoConselhoLocal}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                {{membro.caminhoFormativoFeedback}}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando == true">
                                                    <select class="form-control" id="cores_status_vocacional"
                                                        [(ngModel)]="membro.corStatusVocacionalId"
                                                        name="nparecer_concorda">
                                                        <option [ngValue]="null">Selecione um Status</option>
                                                        <option [ngValue]="status.id"
                                                            *ngFor="let status of listaCorStatusVocacional">
                                                            {{status.nome}}</option>
                                                    </select>
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{membro.corStatusVocacionalNome}}
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>