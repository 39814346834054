import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { Usuario } from 'src/app/model/usuario.model';
import { QuestionarioFormandoService } from '../../questionario/service/questionarioformando.service';
import { ParecerResponsavelService } from '../service/parecerresponsavel.service';
import { UsuarioService } from 'src/app/secured/wop/usuario/service/usuario.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { PessoaService } from 'src/app/secured/wop/pessoa/service/pessoa.service';
import { CelulaService } from 'src/app/secured/wop/celula/service/celula.service';
import { MembroComunidadeService } from 'src/app/secured/wop/celula/service/membro.comunidade.service';
import { CoordenadorColegiadoService } from '../../colegiado/coordenadorcolegiado.service';
import { FormadorComunitarioService } from 'src/app/secured/wop/celula/service/formador.comunitario.service';
import { FormadorAssistenteService } from 'src/app/secured/wop/celula/service/formador.assist.service';
import { DiscernimentoService } from '../../discernimentos/service/discernimento.service';
import {
  MissaoConfigService,
  QuestionarioConfiguracao,
} from '../../missao-config-comp/service/missao-config.service';

@Component({
  selector: 'app-parecer-cc',
  templateUrl: './parecer.cc.celula.component.html',
  styleUrls: [],
})
export class ParecerCoordenadorColegiadoCelulaComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public formadorComunitario: FormadorComunitario;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public pessoaBuilder: PessoaBuilder;

  public totalDePaginas: number = 1;

  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;

  public formadoresDaMissao: any;

  public paginacaoConfig: any;

  questionarioConfiguracao: QuestionarioConfiguracao;

  constructor(
    private missaoConfiguracaoService: MissaoConfigService,
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public coordenadorColegiadoService: CoordenadorColegiadoService,
    public formadorComunitarioService: FormadorComunitarioService,
    public formadorAssistenteService: FormadorAssistenteService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public discernimentoService: DiscernimentoService,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
    this.paginacaoConfig = {
      page: 0,
      size: 10,
      totalPages: 0,
      totalElements: 0,
    };
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    await this.carregarFormadoresCelula();
  }

  carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarQuestionario(pessoaId: number): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorPessoaId(
        pessoaId
      );
      if (response != undefined && response != null) {
        return response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarFormadoresCelula(): Promise<any> {
    try {
      let response =
        await this.discernimentoService.obterMembrosParaDiscernimentoDoResponsavelLocal(
          this.paginacaoConfig.page,
          this.paginacaoConfig.size
        );
      this.formadoresDaMissao = response.content;
      this.paginacaoConfig.totalPages = response.totalPages;
      this.paginacaoConfig.totalElements = response.totalElements;

      if (this.formadoresDaMissao.length > 0) {
        this.formadoresDaMissao = this.formadoresDaMissao.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      for (let i = 0; i < this.formadoresDaMissao.length; i++) {
        if (this.formadoresDaMissao[i].pessoaImagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.formadoresDaMissao[i].pessoaImagemId
          );
          this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
        }

        if (
          this.formadoresDaMissao[i].questionarioFormandoStatus == 'NAOINICIADO'
        ) {
          this.formadoresDaMissao[i].questionarioFormandoStatus =
            'Não Iniciado';
        }
        if (this.formadoresDaMissao[i].parecerStatus == 'NAOINICIADO') {
          this.formadoresDaMissao[i].parecerStatus = 'Não Iniciado';
        }
        if (!this.questionarioFormando || !this.questionarioFormando.id) {
          this.questionarioFormando = await this.carregarQuestionario(
            this.formadoresDaMissao[i].pessoaId
          );

          if (this.questionarioFormando) {
            if (!this.questionarioConfiguracao) {
              this.carregarQuestionarioConfiguracao(
                this.questionarioFormando.questionarioId
              );
            }
          }
        } else if (!this.questionarioConfiguracao) {
          this.carregarQuestionarioConfiguracao(
            this.questionarioFormando.questionarioId
          );
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoParecerCAL(
    questionarioFormandoId: number
  ): Promise<any> {
    try {
      this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
      this.parecerResponsavel.parecer_id = 2;
      this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
      let response = await this.parecerResponsavelService.cadastrar(
        this.parecerResponsavel
      );
      if (response != undefined && response != null) {
        this.parecerResponsavel = response;
        this.router.navigate([
          '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
        ]);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async consultarVinculoParecerCAL(formando: any): Promise<any> {
    try {
      if (formando.questionarioFormandoId != null) {
        let response =
          await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(
            formando.questionarioFormandoId
          );
        if (response != undefined && response != null) {
          this.parecerResponsavel = response;
          this.router.navigate([
            '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
          ]);
        } else {
          this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
        }
      }
      if (formando.questionarioFormandoId == null) {
        this.questionarioFormando = await this.cadastrarVinculoQuestionarioCAL(
          formando.pessoaId
        );
        this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoQuestionarioCAL(pessoaId: number): Promise<any> {
    try {
      this.questionarioFormando.anoFormativoId = 2;
      this.questionarioFormando.questionarioId = 3;
      this.questionarioFormando.pessoaId = pessoaId;
      let response = await this.questionarioFormandoService.cadastrar(
        this.questionarioFormando
      );
      if (response != undefined && response != null) {
        return response;
      } else {
        this.swtAlert2Service.errorAlert(
          'Não foi possível cadastrar um vinculo de questionário para o formando'
        );
        throw new Error(
          'Não foi possível cadastrar um vinculo de questionário para o formando'
        );
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.paginacaoConfig.page = botao.numero - 1;
    this.carregarFormadoresCelula();
  }
}
