export class ParecerResponsavel {

    constructor(
        public id: number,
        public pessoaResponsavelId: number,
        public parecer_id: number,
        public questionarioFormandoId: number,
        public status: string,
        public data_envio: Date     
    ){}

}