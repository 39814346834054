import { Component, OnInit } from '@angular/core';
import { LoggedInUserSharedDataService } from '../../layout/header/service/logged-in-user-shared-data.service';
import { Pessoa } from 'src/app/model/pessoa.model';

@Component({
  selector: 'app-missao-config',
  templateUrl: './missao-config.component.html',
  styleUrls: ['./missao-config.component.css'],
})
export class MissaoConfigComponent implements OnInit {
  responsavelLocalMissaoId: number;

  constructor(
    private loggedInUserSharedDataService: LoggedInUserSharedDataService
  ) {}

  ngOnInit() {
    this.loggedInUserSharedDataService.loggedInPerson$.subscribe(
      (person: Pessoa) => {
        if (person) {
          this.responsavelLocalMissaoId = person.vinculoMissao.missaoAtualId;
        }
      }
    );
  }
}
