<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Gerenciar Célula</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content" name="celula">
            <div class="row">
                <div name="dados_celula" class="col-sm-12 col-md-12 col-lg-12">
                    <div class="card card-gray-dark color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-users"></i>
                                <a routerLink="../../{{celula.id}}" i18n> - Célula {{celula.nome}} </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="celula_nome">Nome</label>
                                        <input [(ngModel)]="celula.nome" name="celula_nome" type="text" class="form-control" id="celula_nome" i18n-placeholder disabled>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="celula_data_fundacao">Data de Fundação</label>
                                        <input [(ngModel)]="celula.dataFundacao" name="celula_data_fundacao" type="date" class="form-control" id="celula_data_fundacao" i18n-placeholder disabled>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="celula_fase">Fase</label>
                                        <input [(ngModel)]="celula.nivelCelulaNome" name="celula_fase" type="text" class="form-control" id="celula_fase" i18n-placeholder disabled>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="celula_cev">Centro de Evangelização</label>
                                        <input class="form-control" type="text" id="celula_cev" [(ngModel)]="celula.centroEvangelizacaoNome" name="celula_cev" i18n-placeholder disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div name="cadastro_formador_comunitario" class="col-sm-12 col-md-12 col-lg-12">
                    <div class="card card-gray-dark color-pallete-box collapsed-card">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-user-alt"></i>
                                <a i18n> Formador Comunitário </a>
                            </h3>
                            <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-plus"></i></button></div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label i18n for="npastor_pessoa">Pessoa</label>
                                                <span class="form-control">{{pessoaFormadorComunitarioSelecionada?.nome}} {{pessoaFormadorComunitarioSelecionada?.sobrenome}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label i18n for="button-add-pessoa">&nbsp;</label>
                                                <button name="button-add-pessoa" data-toggle="modal" data-target="#listagem-pessoa-formador-comunitario" style="cursor: pointer" class="btn btn-primary" i18n>Selecionar Formador Comunitário</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="nfc_dtentrada">Data de Entrada</label>
                                        <input [(ngModel)]="formadorComunitario.dataEntrada" name="nfc_dtentrada" type="date" class="form-control" id="npastor_dtentrada" i18n-placeholder>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="button-part-submit">&nbsp;</label>
                                        <button type="submit" (click)="salvarFormadorComunitario()" name="button-part-submit" class="form-control btn btn-primary" i18n>Adicionar Formador Comunitario</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12">
                                    <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Formador Comunitário'" [cols]="6" [rows]="buscaFormadoresComunitarios.quantityOfElements" [totalDePaginas]="totalDePaginasFormadoresComunitarios" (eventoBotao)="cliqueNoBotaoFormadoresComunitariosListener($event)">
                                        <tr tableheader>
                                            <th style="width: 10px">#</th>
                                            <th>Imagem</th>
                                            <th>Formador</th>
                                            <th>Data de Entrada</th>
                                            <th>Data de Saída</th>
                                            <th></th>
                                        </tr>
                                        <tr tablebody *ngFor="let formador of formadoresComunitarios">
                                            <td>{{formador.id}}</td>
                                            <td>
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle" [src]="formador.thumbnail == null ? 'assets/images/ppl.png' : formador.thumbnail" alt="Imagem do formador">
                                                </div>
                                            </td>
                                            <td><a routerLink="/secured/pessoa/{{formador.pessoaId}}">{{formador.pessoaNome}}</a></td>
                                            <td>{{formador.dataEntrada | date : 'dd/MM/yyyy'}}</td>
                                            <td>
                                                <ng-container *ngIf="formador.editando == false || formador.editando == undefined">
                                                    {{formador.dataSaida != null ? (formador.dataSaida | date : 'dd/MM/yyyy') : 'Formador Comunitário atual'}}
                                                </ng-container>
                                                <ng-container *ngIf="formador.editando == true">
                                                    <input [(ngModel)]="formador.dataSaida" name="npastor_dtsaida" type="date" class="form-control" id="npastor_dtsaida">
                                                </ng-container>
                                            </td>
                                            <td class="text-center">
                                                <button *ngIf="formador.editando == true" class="btn btn-sm btn-success" (click)="atualizarFormador(formador)" title="Atualizar formador" alt="Atualizar formador"><i class="fas fa-save"></i></button>
                                                <button *ngIf="formador.editando == false || formador.editando == undefined" class="btn btn-sm btn-primary" (click)="formador.editando = true" title="Editar formador" alt="Editar formador"><i class="fas fa-edit"></i></button>
                                                <button class="btn btn-sm btn-danger ml-1" (click)="removerFormador(formador.id)" title="Remover formador" alt="Remover formador"><i class="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div name="cadastro_formador_assistente" class="col-sm-12 col-md-12 col-lg-12">
                    <div class="card card-gray-dark color-pallete-box collapsed-card">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-user-alt"></i>
                                <a i18n> Formador Assistente </a>
                            </h3>
                            <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-plus"></i></button></div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label i18n for="npastor_pessoa">Pessoa</label>
                                                <span class="form-control">{{pessoaFormadorAssistenteSelecionada?.nome}} {{pessoaFormadorAssistenteSelecionada?.sobrenome}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label i18n for="button-add-pessoa">&nbsp;</label>
                                                <button name="button-add-pessoa" data-toggle="modal" data-target="#listagem-pessoa-formador-assistente" style="cursor: pointer" class="btn btn-primary" i18n>Selecionar Formador Assistente</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="nfc_dtentrada">Data de Entrada</label>
                                        <input [(ngModel)]="formadorAssistente.dataEntrada" name="nfc_dtentrada" type="date" class="form-control" id="npastor_dtentrada" i18n-placeholder>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <label i18n for="button-part-submit">&nbsp;</label>
                                        <button type="submit" (click)="salvarFormadorAssistente()" name="button-part-submit" class="form-control btn btn-primary" i18n>Adicionar Formador Assistente</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12">
                                    <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Formador Comunitário'" [cols]="6" [rows]="buscaFormadoresAssistentes.quantityOfElements" [totalDePaginas]="totalDePaginasFormadoresAssistentes" (eventoBotao)="cliqueNoBotaoFormadoresAssistentesListener($event)">
                                        <tr tableheader>
                                            <th style="width: 10px">#</th>
                                            <th>Imagem</th>
                                            <th>Formador</th>
                                            <th>Data de Entrada</th>
                                            <th>Data de Saída</th>
                                            <th></th>
                                        </tr>
                                        <tr tablebody *ngFor="let formador of formadoresAssistentes">
                                            <td>{{formador.id}}</td>
                                            <td>
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle" [src]="formador.thumbnail == null ? 'assets/images/ppl.png' : formador.thumbnail" alt="Imagem do formador">
                                                </div>
                                            </td>
                                            <td><a routerLink="/secured/pessoa/{{formador.pessoaId}}">{{formador.pessoaNome}}</a></td>
                                            <td>{{formador.dataEntrada | date : 'dd/MM/yyyy'}}</td>
                                            <td>
                                                <ng-container *ngIf="formador.editando == false || formador.editando == undefined">
                                                    {{formador.dataSaida != null ? (formador.dataSaida | date : 'dd/MM/yyyy') : 'Formador Comunitário atual'}}
                                                </ng-container>
                                                <ng-container *ngIf="formador.editando == true">
                                                    <input [(ngModel)]="formador.dataSaida" name="npastor_dtsaida" type="date" class="form-control" id="npastor_dtsaida">
                                                </ng-container>
                                            </td>
                                            <td class="text-center">
                                                <button *ngIf="formador.editando == true" class="btn btn-sm btn-success" (click)="atualizarFormadorAssistente(formador)" title="Atualizar formador" alt="Atualizar formador"><i class="fas fa-save"></i></button>
                                                <button *ngIf="formador.editando == false || formador.editando == undefined" class="btn btn-sm btn-primary" (click)="formador.editando = true" title="Editar formador" alt="Editar formador"><i class="fas fa-edit"></i></button>
                                                <button class="btn btn-sm btn-danger ml-1" (click)="removerFormadorAssistente(formador.id)" title="Remover formador" alt="Remover formador"><i class="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div name="listagem_membros" class="col-sm-12 col-md-12 col-lg-12">
                    <div class="card card-gray-dark color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-child"></i>
                                <a i18n> Membros </a>
                            </h3>
                            <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-minus"></i></button></div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Membros'" [cols]="6" [rows]="buscaMembros.quantityOfElements" [totalDePaginas]="totalDePaginasMembros" (eventoBotao)="cliqueNoBotaoMembrosListener($event)">
                                        <tr tableheader>
                                            <th>#</th>
                                            <th>Imagem</th>
                                            <th>Nome</th>
                                            <th>Data Entrada Célula</th>
                                            <th>Data Saída do Célula</th>
                                            <th></th>
                                        </tr>
                                        <tr tablebody *ngFor="let membro of membrosCelula">
                                            <td>{{membro.id}}</td>
                                            <td>
                                                <div class="text-center">
                                                    <!--a routerLink="/secured/local/pgo/celula/membro/{{membro.id}}/detalhes"-->
                                                        <img class="profile-user-img img-fluid img-circle" [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="Imagem do membro">
                                                    <!--/a-->
                                                </div>
                                            </td>
                                            <td><a routerLink="/secured/local/pgo/celula/membro/{{membro.id}}/detalhes">{{membro.pessoaNome}}</a></td>
                                            <td>{{membro.dataEntrada | date : 'dd/MM/yyyy'}}</td>
                                            <td>
                                                <ng-container *ngIf="membro.editando == false || membro.editando == undefined">
                                                    {{membro.dataSaida != null ? (membro.dataSaida | date : 'dd/MM/yyyy') : 'Membro Atual'}}
                                                </ng-container>
                                                <ng-container *ngIf="membro.editando == true">
                                                    <input [(ngModel)]="membro.dataSaida" name="nmembro_dtsaida" type="date" class="form-control" id="nmembro_dtsaida">
                                                </ng-container>
                                            </td>
                                            <td class="text-center">
                                                <!--button *ngIf="membro.editando == true" class="btn btn-sm btn-success" (click)="atualizarMembro(membro)" title="Atualizar membro" alt="Atualizar membro"><i class="fas fa-save"></i></button>
                                                <button *ngIf="membro.editando == false || membro.editando == undefined" class="btn btn-sm btn-primary" (click)="membro.editando = true" title="Editar membro" alt="Editar membro"><i class="fas fa-edit"></i></button>
                                                <button class="btn btn-sm btn-danger ml-1" (click)="removerMembro(membro.id)" title="Remover membro" alt="Remover membro"><i class="fas fa-trash-alt"></i></button-->
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                             
            </div>
        </section>

    </div>
    <listagem-pessoainfo-modal-component 
        [modalId]="'listagem-pessoa-formador-comunitario'" 
        [onlyMissionaries]="true" 
        [bringNotInformedWayOfLife]="false" 
        [filterByUserAccess]="true" 
        [bringEntitiesWithoutLinkedUser]="false" 
        [modalTitle]="'Selecione o Formador Comunitário'" 
        [buttonText]="'Selecionar'" 
        [altButtonText]="'Selecionar Formador'" 
        (eventEmitter)="selecionarFormadorComunitario($event)">
    </listagem-pessoainfo-modal-component>
    <listagem-pessoainfo-modal-component 
        [modalId]="'listagem-pessoa-formador-assistente'" 
        [onlyMissionaries]="true" 
        [bringNotInformedWayOfLife]="false" 
        [filterByUserAccess]="true" 
        [bringEntitiesWithoutLinkedUser]="false" 
        [modalTitle]="'Selecione o Formador Assistente'" 
        [buttonText]="'Selecionar'" 
        [altButtonText]="'Selecionar Formador'" 
        (eventEmitter)="selecionarFormadorAssistente($event)">
    </listagem-pessoainfo-modal-component>
</secured>