import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { Usuario } from 'src/app/model/usuario.model';
import { QuestionarioFormandoService } from '../questionario/service/questionarioformando.service';
import { ParecerResponsavelService } from './service/parecerresponsavel.service';
import { CaminhoFormativoService } from './service/caminhoformativo.service';
import { UsuarioService } from '../../wop/usuario/service/usuario.service';
import { ImagemService } from '../../wop/imagem/service/imagem.service';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';
import { CelulaService } from '../../wop/celula/service/celula.service';
import { MembroComunidadeService } from '../../wop/celula/service/membro.comunidade.service';
import { FormadorComunitarioService } from '../../wop/celula/service/formador.comunitario.service';
import { ElementoGrupoDominioLocalService } from '../grupodominio/service/elementogrupodominio.local.service';
import {
  MissaoConfigService,
  QuestionarioConfiguracao,
} from '../missao-config-comp/service/missao-config.service';

@Component({
  selector: 'app-parecer',
  templateUrl: './parecer.celula.autoridade.component.html',
  styleUrls: [],
})
export class ParecerCelulaAutoridadeComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public formadorComunitario: FormadorComunitario;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public pessoaBuilder: PessoaBuilder;

  public totalDePaginas: number = 1;

  public totalDePaginasMembros: number;
  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public celulaId: number;

  public listaCorStatusVocacional!: Array<any>;

  questionarioConfiguracao: QuestionarioConfiguracao;

  constructor(
    private missaoConfiguracaoService: MissaoConfigService,
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public formadorComunitarioService: FormadorComunitarioService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public caminhoFormativoService: CaminhoFormativoService,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    this.celulaId = Number(this.route.snapshot.paramMap.get('id'));
    await this.carregarCorStatusVocacional();
    await this.carregarMembrosCelula();
  }

  carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarCorStatusVocacional(): Promise<any> {
    try {
      this.listaCorStatusVocacional =
        await this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao(
          'COR_STATUS_VOCACIONAL'
        );
    } catch (e) {
      console.log(e);
    }
  }

  async carregarQuestionario(pessoaId: number): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorPessoaId(
        pessoaId
      );
      if (response != undefined && response != null) {
        return response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarMembrosCelula(): Promise<any> {
    try {
      this.membrosCelula = await this.membroService.listarMembrosCelula(
        this.celulaId
      );
      this.totalDePaginasMembros = 1;

      if (this.membrosCelula.length > 0) {
        this.membrosCelula = this.membrosCelula.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      this.membrosCelula.forEach(async (m) => {
        if (!this.questionarioFormando || !this.questionarioFormando.id) {
          this.questionarioFormando = await this.carregarQuestionario(
            m.pessoaId
          );

          if (this.questionarioFormando) {
            if (!this.questionarioConfiguracao) {
              this.carregarQuestionarioConfiguracao(
                this.questionarioFormando.questionarioId
              );
            }
          }
        } else if (!this.questionarioConfiguracao) {
          this.carregarQuestionarioConfiguracao(
            this.questionarioFormando.questionarioId
          );
        }

        if (m.pessoaImagemId != null) {
          let blogImage = await this.getPessoaImagem(m.pessoaImagemId);
          this.imagemService.createImage(blogImage, m);
        }
      });
    } catch (err) {
      this.swtAlert2Service.errorAlert(JSON.stringify(err.error));
    }
  }

  async cadastrarConsideracaoConselhoLocal(membro): Promise<any> {
    try {
      await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
        membro
      );
      await this.carregarMembrosCelula();
      this.swtAlert2Service.successAlert(
        'Parecer de consideração do Conselho Local atualizado com sucesso!'
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.carregarMembrosCelula();
  }

  concordanciaConselhoLocal(membro: any) {
    if (membro.consideracaoCondorda === true) {
      membro.caminhoFormativoFeedback =
        membro.caminhoFormativoSugeridoFormadorComunitario;
      membro.consideracaoConselhoLocal = '';
    } else {
      membro.caminhoFormativoFeedback = '';
    }
  }

  copiarTextoConsideracaoConselhoLocalParaFeedback(membro: any) {
    membro.caminhoFormativoFeedback = membro.consideracaoConselhoLocal;
  }

  obterCorDaLinha(membro: any) {
    let cssObject = {};

    if (membro.corStatusVocacionalValor != null) {
      cssObject['background-color'] = membro.corStatusVocacionalValor;
    }

    if (
      membro.corStatusVocacionalNome != null &&
      membro.corStatusVocacionalNome.localeCompare('Desligamento') == 0
    ) {
      cssObject['color'] = '#fff';
    }

    return cssObject;
  }
}
