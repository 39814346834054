import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { QuestionarioFormando } from '../model/questionarioformando.model';

@Injectable()
export class QuestionarioFormandoBuilder extends AbstractBuilder<QuestionarioFormando> {

    reset() : void {
        this.entity = new QuestionarioFormando(null,null,null,null,null,null);
    }

}